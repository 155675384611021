import React from 'react';
import PropTypes from 'prop-types';
import Panel from 'ic/ui-elem/Panel';
import extractColor from './extractColor.js';
import logger from 'score/logSvc';
import CollapseMod from 'ui/CollapseMod.jsx';
import { str } from 'seco/localeSvc';
import classnames from 'classnames';

const log = logger.getLogger(name); // eslint-disable-line

const Text = (p) => (
  <div className={'general-text ic-col-12'}>
    {p.title ? (
      <div className={'ic-grid-center ic-col-12'}>
        <h2 className={'ic-unset-margin'}> {p.title} </h2>
      </div>
    ) : (
      ''
    )}
    <div dangerouslySetInnerHTML={{ __html: p.content }} />
  </div>
);

export default class Paragraph extends React.Component {
  /**
   * propTypes
   * @property {string} gridSpan
   * @property {string} title
   * @property {string} content
   */
  static get propTypes() {
    return {
      gridSpan: PropTypes.string,
      summaryHeight: PropTypes.string,
      styleClass: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.string,
      mode: PropTypes.oneOf(['default', 'inverted', 'skeleton', '']),
      color: PropTypes.oneOf(['primary', 'secondary', 'panel', 'error', 'success', 'info', 'pale', 'puff', '']),
    };
  }
  render() {
    let cssClass = 'ic-grid-noGutter-middle ';
    let color = extractColor(this.props.color);
    let mode = this.props.mode;

    if (color === '') {
      mode = 'default';
      color = '';
    }

    if (this.props.gridSpan) {
      cssClass += this.props.gridSpan;
    }

    const content = (
      <div className={cssClass}>
        <Text title={this.props.title} content={this.props.content} />
      </div>
    );

    return (
      <div
        data-ssr-key={this.props.SSRKey}
        className={classnames('my-2 ic-cms-paragraph smartEditComponent', this.props.summaryHeight ? 'content-bkg' : '')}
        data-smartedit-component-id={this.props.SSRKey}
        data-smartedit-component-uuid={this.props.elementUid}
        data-smartedit-catalog-version-uuid={this.props.contentSlotCatalogVersion}
        data-smartedit-component-type={this.props.componentParentType}
      >
        <Panel color={color} mode={mode} className={this.props.styleClass} SSRKey={this.props.SSRKey}>
          {this.props.summaryHeight ? (
            <>
              <div className={''}>
                <CollapseMod
                  expandLabel={' + ' + str('phrase.read_more')}
                  collapseLabel={' - ' + str('phrase.read_less')}
                  minHeight={this.props.summaryHeight}
                  color={'primary'}
                  alignment={'center'}
                  componentId={this.props.SSRKey}
                >
                  {content}
                </CollapseMod>
              </div>
              {/* <div className={'hidden md:block'} style={{ minHeight: this.props.summaryHeight }}>
                {content}
              </div> */}
            </>
          ) : (
            content
          )}
        </Panel>
      </div>
    );
  }
}
