const extractColor = (color, textColor, bkgColor) => {
  const validColors = ['primary', 'secondary', 'tertiary', 'panel', 'error', 'success', 'info', 'pale', 'puff'];

  let resultingColor = '';
  if (color) {
    resultingColor = color;
  }

  if (!resultingColor && textColor) {
    resultingColor = textColor;
  }

  if (!resultingColor && bkgColor) {
    resultingColor = bkgColor;
  }

  if (validColors.indexOf(resultingColor) >= 0) {
    return resultingColor;
  }
  return '';
};

export default extractColor;
