import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { getThemeFactory } from '@ic-theme';

// eslint-disable-next-line no-unused-vars
const theme = getThemeFactory('Panel', {});

export default class Panel extends React.Component {
  /**
   * propTypes
   * @property {string} src
   * @property {string} centered
   * @property {oneOf} iconAlignment
   */
  static get propTypes() {
    return {
      className: PropTypes.string,
      backgroundColor: PropTypes.string,
      color: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'panel', 'error', 'success', 'info', 'pale', 'puff', '']),
      padding: PropTypes.oneOf(['none', 'half', 'default', 'double']),
      mode: PropTypes.oneOf(['default', 'inverted', 'skeleton', '']),
      layout: PropTypes.oneOf(['default', 'grid', 'grid-noGutter', 'flex']),
      children: PropTypes.node,
    };
  }

  Content() {}

  render() {
    let colorClass;
    if (this.props.color) {
      colorClass = 'col-' + this.props.color;
    }
    let paddingClass;
    if (this.props.mode && this.props.mode !== 'default') {
      colorClass += '-' + this.props.mode;
    }

    switch (this.props.padding) {
      case 'half':
        paddingClass = 'ic-half-padding';
        break;
      case 'default':
        paddingClass = 'ic-padding';
        break;
      case 'double':
        paddingClass = 'ic-double-padding';
        break;
      default:
        paddingClass = '';
    }

    // Handle the laout settings
    let layoutClass = '';
    if (this.props.layout === 'grid') {
      layoutClass = 'ic-grid';
    }
    if (this.props.layout === 'grid-noGutter') {
      layoutClass = 'ic-grid-noGutter';
    }
    if (this.props.layout === 'flex') {
      layoutClass = 'flex';
    }
    const SP = (
      <div
        style={{ backgroundColor: this.props.backgroundColor }}
        className={classnames('ic-ui-panel', colorClass, paddingClass, layoutClass, this.props.className)}
      >
        {this.props.children}
      </div>
    );
    if (this.props.SSRKey) {
      return <div data-ssr-key={this.props.SSRKey}>{SP}</div>;
    }
    return <>{SP}</>;
  }
}

Panel.defaultProps = {
  color: 'pale',
  mode: 'default',
  layout: 'default',
  padding: 'none',
};
