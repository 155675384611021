import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cloneDeep from 'fast-clone';
import SlideInOut from 'ui/SlideInOut';
import { setupInitialState, mapItems, setupMutations, areStatePropsEqual } from 'core/util/module-utils';
import logger from 'score/logSvc';
import { str } from 'seco/localeSvc';
import classnames from 'classnames';

// Setup module multi/single instance name etc
const multipleInstances = true;
const name = 'Collapse';

// Modules data, this is the initial data for an instance

const initialState = { collapsed: {} };

const log = logger.getLogger(name); // eslint-disable-line
const conf = { multipleInstances, name, initialState };

// ################# GETTERS  #################
export const getters = (state, ownProps) => {
  // Leave this line fetches ta state variable depending on the module is using instances or not
  const instance = cloneDeep(mapItems(state, conf, ownProps.iid));
  // Adding an extra getter that is not only a variable but some calculation based on the state
  return instance;
};

// ################# ACTIONS  #################
export const actions = (dispatch, ownProps) => ({
  collapse: (componentId) => dispatch({ type: 'COLLAPSE_COLLAPSE', iid: ownProps.iid, componentId }),
  expand: (componentId) => dispatch({ type: 'COLLAPSE_EXPAND', iid: ownProps.iid, componentId }),
});

// ################# MUTATIONS  #################
/* eslint-disable no-param-reassign, no-unused-vars */
const mutations = {
  COLLAPSE_COLLAPSE: (state, action) => {
    if (!state.collapsed[action.componentId]) {
      state.collapsed[action.componentId] = true;
    }
  },
  COLLAPSE_EXPAND: (state, action) => {
    state.collapsed[action.componentId] = false;
  },
};
/* eslint-enable */
// ################# MODULE SETUP DON T TOUCH  #################
export const _module = {
  name,
  state: setupInitialState(initialState, conf),
  actions,
  mutations: setupMutations(mutations, conf) // eslint-disable-line
};

// ################# RENDER  #################

class Collapse extends React.Component {
  static get propTypes() {
    return {
      expandLabel: PropTypes.string,
      collapseLabel: PropTypes.string,
      children: PropTypes.node,
    };
  }

  componentDidMount() {
    this.props.collapse(this.props.componentId);
  }

  /**
   * Render function for react, called very time there is state change.
   * @returns {Html} The rendered code
   */
  render() {
    let color;
    if (this.props.color) {
      color = 'col-' + this.props.color + '-inverted';
    }
    let alignment;
    if (this.props.alignment) {
      alignment = 'text-' + this.props.alignment;
    }
    return (
      <>
        <SlideInOut duration={300} minHeight={this.props.minHeight} isOpened={!this.props.collapsed[this.props.componentId]}>
          <div key="fixed">{this.props.children}</div>
        </SlideInOut>
        <div
          className={classnames('cursor-pointer ic-padding-t ic-text-right', alignment)}
          onClick={
            this.props.collapsed[this.props.componentId]
              ? () => {
                  this.props.expand(this.props.componentId);
                }
              : () => {
                  this.props.collapse(this.props.componentId);
                }
          }
        >
          <span className={classnames('collapse-anchor cursor-pointer', color)}>
            {this.props.collapsed[this.props.componentId]
              ? this.props.expandLabel || str('phrase.read_more')
              : this.props.collapseLabel || str('phrase.read_less')}
          </span>
        </div>
      </>
    );
  }
}

Collapse.defaultProps = {
  position: 'right',
};
export default connect(getters, actions, undefined, {
  areStatePropsEqual,
})(Collapse);

require("core/redux/reducer").registerModule(name, _module);require("@spa-core/redux/store").newModuleLoaded();